import React, { useEffect, useState } from "react";
import "./TodayFocus.scss";
import app from "../../common/common";
import { useNavigate } from "react-router-dom";
function TodayFocus() {
  const navigate = useNavigate();
  const [tagList, setTagList] = useState([]);
  useEffect(() => {
    getTodayFocus();
  }, []);
  async function getTodayFocus() {
    let a = await app.spiderTodayFocus();
    setTagList(a);
  }
  return (
    <div className="today_focus" id="TodayFocus">
      <div className="today_focus_nav">
        <div className="title">今日焦点</div>
        <div className="tag">最新时事</div>
        <div className="move">{/* 更多{'>'} */}</div>
      </div>

      <div className="today_focus_content">
        <img src={require("../../assets/web_s_img.png")} alt="" />
        <div className="today_focus_content_tag_list">
          {tagList.map((i) => {
            return (
              <div
                className="tag_item"
                key={i.value}
                onClick={() =>
                  navigate("/todayfocusdetail", { state: { url: i.href } })
                }
              >
                {i.value}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TodayFocus;
