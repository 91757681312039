import React from "react";
import "./Footer.scss";
// import PropTypes from 'prop-types'

function Footer(props) {
  return (
    <footer id="callus">
      <div className="top">
        <div className="qrcode">
          <div className="item">
            <img src={require('../../assets/qecode.png')} alt="" />
            <div>app下载</div>
          </div>
          {/* <div className="item">
            <img src="" alt="" />
            <div>IOS下载</div>
          </div> */}
        </div>
        <div className="text">
          <div>
            <div className="title">联系我们</div>
            <div className="content">联系邮箱： carfriendonline@163.com</div>
          </div>
          {/* <div>
            <div className="title">关于我们</div>
            <div className="content"></div>
            
          </div> */}
        </div>
      </div>
      <div className="bottom"><a href="https://beian.miit.gov.cn/">ICP备案 粤ICP备2021054334号  </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44011102003199">粤公网安备 44011102003199号</a></div>
    </footer>
  );
}

// Footer.propTypes = {}

export default Footer;
