import React from "react";
import "./ContactUs.scss";
import { Map,  InfoWindow } from "react-amap";

function ContactUs() {
  const offset = [0, 0];
  const size = {
    width: 500,
    height: 30,
  };
  return (
    <div className="contact_us">
      <div className="contact_us_nav">
        <div className="title">联系我们</div>
        <div className="tag">随时联系</div>
      </div>

      <div className="contact_us_content">
        <div className="contact_us_content_text">
          <div className="email">
            <img src={require("../../assets/web_s_mailbox.png")} alt="" />
            <div className="text">
              <div>联系邮箱：</div>
              <div>carfriendonline@163.com</div>
            </div>
          </div>
          <div className="address">
            <img src={require("../../assets/web_s_address.png")} alt="" />
            <div className="text">
              <div>公司地址：</div>
              <div>广东省广州市天河区广州大道北路613号五楼508室C32</div>
            </div>
          </div>
        </div>

        <div className="contact_us_content_location">
          <Map amapkey={"35a163f24b3f33307a3813e500f92f94"} events={()=>{}} zoom={12}>

            <InfoWindow
              position={[113.30505506727431, 23.216895616319444]}
              visible={true}
              isCustom={false}
              content={"广东省广州市天河区广州大道北路613号五楼508室C32"}
              size={size}
              offset={offset}
              
            />
          </Map>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
