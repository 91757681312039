import React, { useEffect, useState } from "react";
import "./TodayFocusDetail.scss";
import { useLocation } from "react-router-dom";
import app from "../../common/common";
function TodayFocusDetail() {
  const params = useLocation();
  const [detail,setDetail] = useState({})
  useEffect(()=>{
    window.scrollTo(0, 0);
    console.log(params)
    getTodayFocusDetail(params.state.url)
  },[])

  async function getTodayFocusDetail(url){
    let a = await app.spiderTodayFocusDetail(url)
    console.log(a)
    setDetail(a)
  }
  return (
    <div className="today_focus_detail">
      <h1 className="focus_detail_title">{detail.title}</h1>
      <div className="time_writer">
        <div className="time">
          <img src="" alt="" />
          {detail.time}
        </div>

        <div className="writer">
          <img src="" alt="" />
          {detail.writer}
        </div>
      </div>
      <div className="content">
        {
          detail.arr ? detail.arr.map((i,v)=>{
            if(i.type === "text"){
              return <p key={v}>&nbsp;&nbsp;&nbsp;&nbsp;{i.content}</p>
            }else{
              return <div className="img_content" key={v}><img src={i.content} /><meta name="referrer" content="no-referrer"></meta></div> 
            }
          }) : null
        }
      </div>
    </div>
  );
}

export default TodayFocusDetail;
