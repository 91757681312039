import React, { useEffect } from "react";
import './Header.scss'
import { useNavigate } from "react-router-dom";

// import PropTypes from "prop-types";
export default React.memo((props) => {
  let navigate = useNavigate();
  useEffect(()=>{
    console.log(123465)
  },[])
  return (
    <header>
      <nav>
        <div className="top">
          <div className="left">
            {/* <div>
              广州<span>【切换城市】</span>
            </div> */}
            <div onClick={()=>navigate("/")}>首页</div>
          </div>
          <div className="right">
            <a href="#callus">联系我们</a>
            {/* <div>关于我们</div> */}
          </div>
        </div>

        <div className="op">
          <div className="logo">
            <img src={require('../../assets/logo.png')} alt="" />
            {/* <div>车友在线</div> */}
          </div>

          <div className="status">今日不限行</div>

          <div className="search search_active">
            <img src={require('../../assets/car_search.png')} alt="" className="search_icon" />
            <input type="text" placeholder="请输入关键字" />
            <button className="search_button">搜索</button>
          </div>

          <div className="qrcode">
            <img src={require('../../assets/qecode.png')} alt="" />
            <div>APP下载</div>
          </div>

          <div className="login">
            <div className="login_button" onClick={()=>navigate("/login")}>登录</div>
          </div>
        </div>
      </nav>
    </header>
  );
})

// Header.propTypes = {};


