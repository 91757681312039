import React from "react";
import "./CarPictures.scss";
import car_pictures_content from "./CarPictures.json";
import { useNavigate } from "react-router-dom";

function CarPictures() {
  let navigate = useNavigate();
  return (
    <div className="car_pictures" id="CarPictures">
      <div className="car_pictures_nav">
        <div className="title">汽车图片</div>
        <div className="move" onClick={() => navigate("/carselection",{state:{type:2}})}>
          更多{">"}
        </div>
      </div>

      <div className="car_pictures_content">
        <div className="car_pictures_big" onClick={() => navigate("/carselection",{state:{type:2}})}>
          <img
            src={require("../../assets/" +
              car_pictures_content.car_pictures_big_img)}
            alt=""
          />
          <div className="car_pictures_big_title">
            {car_pictures_content.car_pictures_big_title}
          </div>
          <div className="car_pictures_big_text">
            {car_pictures_content.car_pictures_big_text}
          </div>

          <div className="car_pictures_big_tag">
            {car_pictures_content.car_pictures_big_tag}
          </div>
        </div>

        <div className="car_pictures_list">
          {car_pictures_content.car_pictures_list.map((i) => {
            return (
              <div className="car_pictures_item" key={i.car_pictures_item_tag} onClick={() => navigate("/carselection",{state:{type:2}})}>
                <img
                  src={require("../../assets/" + i.car_pictures_item_img)}
                  alt=""
                />
                <div className="car_pictures_item_tag">
                  {i.car_pictures_item_tag}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CarPictures;
