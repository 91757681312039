import axios from "axios";
const app = {
  async spider(url, type) {
    let a = await axios.get(url).then((res) => {

      let objE = document.createElement("body");
      objE.innerHTML = res.data;
      let arr = [];
      if (type === 1) {
        let price = objE.querySelectorAll(".bigPic .pri"); //价格
        let carimg = objE.querySelectorAll(".bigPic .pic img"); //车辆图片
        let carhref = objE.querySelectorAll(".bigPic .pic a"); //详情链接
        for (let i = 0; i < price.length; i++) {
          let obj = {};
          obj.car_item_img = carimg[i].attributes[5].nodeValue;
          obj.car_href = carhref[i].attributes[0].nodeValue;
          obj.car_item_title = carimg[i].alt;
          obj.car_item_price =
            price[i].children[0].localName === "span"
              ? price[i].children[0].innerText
              : "暂无报价";
          obj.price =
            price[i].children[0].localName === "span"
              ? price[i].children[0].innerText.substring(0, 5)
              : "0";
          arr.push(obj);
        }

        return arr;
      } else {
        let car = objE.querySelectorAll(".picCen"); //价格
        let carimg = objE.querySelectorAll(".picCen img");
        for (let i = 0; i < car.length; i++) {
          let obj = {};
          obj.car_item_title = car[i].children[0].childNodes[3].innerText;
          obj.car_item_href = car[i].children[0].href.replace(
            "http://" + car[i].children[0].host,
            ""
          );
          obj.car_item_img = carimg[i].attributes[1].nodeValue;

          arr.push(obj);
        }
        return arr;
      }
    });
    return a;
  },
  async spiderImg(url) {
    let a = await axios.get(url).then((res) => {
      let arr = [];
      console.log(res.data);

      let objE = document.createElement("body");

      objE.innerHTML = res.data;
      let carimg = objE.querySelectorAll(".imgWrap img"); //车辆图片
      console.log(carimg);
      carimg.forEach((i) => {
        let obj = {};
        console.log(i.attributes[2].nodeValue);
        obj = {
          src: i.attributes.length === 3 ? i.attributes[1].nodeValue : i.attributes[2].nodeValue,
          // 另一段图片占位文字
          alt: "图片",
        };
        arr.push(obj);
        
      });
      return arr
      
    });
    return a
  },
  async spiderDetail(url) {
    let a = await axios.get(url).then((res) => {
      

      let objE = document.createElement("body");

      objE.innerHTML = res.data;
      
      let carimg = objE.querySelectorAll(".pic-color .pic img"); //车辆图片
      let price = objE.querySelector("#dfCtrId")
      let des = objE.querySelectorAll(".price .des p")
      let con = objE.querySelectorAll("#contentdivOnsale .con dd")
      let obj = {}
      obj.car_img = carimg[0].attributes[1].nodeValue
      obj.price = price.innerText
      obj.des = []
      obj.con = []
      obj.txts = []
      des.forEach(i=>{
        let o = {}
        o.span = i.children[0].innerText
        o.text = i.children[1].innerText
        obj.des.push(o)
      })
      con.forEach(i=>{
        let o = {}
        o.name = i.children[0].children[0].children[0].innerText
        o.config = i.children[0].children[1].children[0].innerText
        o.price = i.children[2].innerText
        obj.con.push(o)
      })
      

      return obj
      
    });
    return a
  },
  async spiderTodayFocus() {
    let a = await axios.get("https://www.pcauto.com.cn/").then((res) => {
      

      let objE = document.createElement("body");

      objE.innerHTML = res.data;
      let u = objE.querySelectorAll(".txts-poin li a")
      
      let arr = []
      u.forEach(i=>{
        let obj = {}
        obj.value = i.innerText
        obj.href = i.href
        arr.push(obj)
      })
      return arr
    });
    return a
  },
  async spiderTodayFocusDetail(url) {
    let a = await axios.get(url).then((res) => {
      

      let objE = document.createElement("body");

      objE.innerHTML = res.data;
      let title = objE.querySelector(".artTit .tit")
      let time = objE.querySelector("#pubtime_baidu")
      let writer = objE.querySelector("#author_baidu")
      let divArt = objE.querySelectorAll("#divArt .artText p")
      let detail = {}
      detail.arr = []
      detail.title = title.innerText
      detail.time = time.innerText
      detail.writer = writer.innerText
      divArt.forEach((i,v)=>{
        let obj = {}
        i.id = v
        if(i.innerText){  //true 是文字
          obj.type = "text"
          
          if(v === 0){
            obj.content = i.innerText.substring(15,i.innerText.length)
          }else{
            obj.content = i.innerText
          }
        }else { //图片
          obj.type = "img"
          let img = i.querySelector("img")
          // console.log(img.attributes[5].nodeValue)

          obj.content = img.attributes.length >= 5 ?  img.attributes[5].nodeValue : ""
        }
        detail.arr.push(obj)
      })
      
      return detail
    });
    return a
  },
  debounce(delay, callback) {
    //delay定时器时间，callback回调函数
    let timer;
    return function (value) {
      clearTimeout(timer); // 清除内存里的定时器
      timer = setTimeout(function () {
        // 将定时器放进内存中
        callback(value);
      }, delay);
    };
  },
};
export default app;
