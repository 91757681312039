import React, { useEffect, useState } from "react";
import { Slider } from "antd";
import "./CarSelection.scss";
import car_selection_data_list from "./CarSelection.json";
import app from "../../common/common.js";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { PhotoSlider } from "react-photo-view";
// import { ImageList, photoImages, ViewBox } from './doc-components';

function CarSelection() {
  let navigate = useNavigate();
  const marks = {
    0: "5万",
    10: "10万",
    20: "20万",
    30: "30万",
    40: "40万",
    50: "50万",
    60: "60万",
    70: "70万",
    80: "80万",
    90: "90万",
    100: "100万",
  };
  const params = useLocation();
  const type = params.state ? params.state.type : 1; // type参数，用于判断当前进入的页面展示
  const [car_selection_data, setcar_selection_data] = useState({
    letter: [],
    letter_brand_list: [],
  }); // 数据

  const [carList, setCarList] = useState([]); // 展示数据

  const [carImgList, setCarImgList] = useState([]); // 图片列表
  const [visible, setVisible] = useState(false);
  const [index, setIndex] = useState(0);

  const [conditionOp, setConditionOp] = useState(100);
  const [conditionOpValue, setConditionOpValue] = useState(100);

  useEffect(() => {

    window.scrollTo(0, 0);
    if (type === 1) { //品牌
      setcar_selection_data(car_selection_data_list);
      handleCarClass(
        "https://" +
          car_selection_data_list.letter_brand_list[0].brand_list[0].href,
        type
      );
    } else if (type === 2){ // 图片
      let a = JSON.parse(JSON.stringify(car_selection_data_list));
      a.letter_brand_list.forEach((i) => {
        i.brand_list.forEach((i2) => {
          i2.href = i2.href.replace("/price/", "/cars/");
        });
      });
      console.log(a);
      setcar_selection_data(a);
      handleCarClass(
        "https://" + a.letter_brand_list[0].brand_list[0].href,
        type
      );
    } 
  }, []);

  const handleCarClass = async (url, type) => {
    // 请求数据
    let a = await app.spider(url, type);
    setCarList(a);
    // setData(a);
  };

  const handleSlider = (e) => {
    app.debounce(1500, (res) => {
      setConditionOpValue(res);
      setConditionOp(res);
    })(e);
  };

  /**
   * 获取汽车图片列表
   * @param {string} url 请求链接
   */
  const handleGetCarImgList = async (url) => {
    let a = await app.spiderImg(url, type);
    console.log(a);
    setCarImgList(a);
    setIndex(0);
    setVisible(true);
  };

  return (
    <div className="car_selection">
      <div className="car_selection_nav">
        <Link to={"/"}>车友在线{">"}</Link>
        <span>精准选车</span>
      </div>

      <div className="car_selection_content">
        <div className="letter">
          {car_selection_data.letter.map((i) => {
            return (
              <a href={"#" + i} className="letter_item" key={i}>
                {i}
              </a>
            );
          })}
        </div>
        <div className="scrllow_view">
          {car_selection_data.letter_brand_list.map((i) => {
            return (
              <div className="scrllow_view_item" key={i.letter}>
                <div className="scrllow_view_letter" id={i.letter}>
                  {i.letter}
                </div>

                {i.brand_list.map((i2) => {
                  return (
                    <div
                      className="scrllow_view_letter_brand"
                      onClick={() => handleCarClass("https://" + i2.href,type)}
                      key={i2.name}
                    >
                      {i2.name}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>

        <div className="choice_content">
          <div className="title">{type === 1 ? "精准选车" : "实拍"}</div>

          {type === 1 ? (
            <div className="condition_op">
              <span className="condition_op_title">已选条件：</span>

              {conditionOp && conditionOp !== 100 ? (
                <div className="condition_item">5万 - {conditionOp}万</div>
              ) : null}

              {conditionOp && conditionOp !== 100 ? (
                <span
                  className="remove_op"
                  onClick={() => {
                    setConditionOp(100);
                    setConditionOpValue(100);
                    handleSlider(0);
                  }}
                >
                  清空条件
                </span>
              ) : null}
            </div>
          ) : null}

          {type === 1 ? (
            <div className="price_op">
              <span>价格：</span>
              <Slider
                marks={marks}
                step={null}
                defaultValue={100}
                value={conditionOpValue}
                onChange={(e) => handleSlider(e)}
              />
            </div>
          ) : null}

          <div className="car_selection_car_list">
            {carList.length > 0
              ? carList.map((i) => {
                  return (
                    <div
                      className="car_selection_car_item"
                      key={i.car_item_title}
                      onClick={() =>
                        type === 2
                          ? handleGetCarImgList(
                              "https://price.pcauto.com.cn" + i.car_item_href
                            )
                          : navigate("/cardetail",{state:{url:"https://price.pcauto.com.cn" + i.car_href}})
                      }
                    >
                      <img src={i.car_item_img} alt="" />
                      <meta name="referrer" content="no-referrer"></meta>
                      <div className="car_item_title">{i.car_item_title}</div>
                      {i.car_item_price ? (
                        <div className="car_item_price">{i.car_item_price}</div>
                      ) : null}
                      {/* <div className="car_item_op">
                    <span>报价</span>
                    <span>图片</span>
                  </div> */}
                    </div>
                  );
                })
              : null}
          </div>


        </div>
      </div>
      <PhotoSlider
        images={carImgList.map((item) => ({ src: item.src, key: item.src }))}
        visible={visible}
        onClose={() => setVisible(false)}
        index={index}
        onIndexChange={setIndex}
      />
    </div>
  );
}

export default CarSelection;
