import React from "react";
import "./HotBrand.scss";
import hot_brand_list from "./HotBrand.json";
import { useNavigate } from "react-router-dom";

function HotBrand() {
  let navigate = useNavigate();
  return (
    <div className="hot_brand" id="HotBrand">
      <div className="hot_brand_nav">
        <div className="title">热门品牌</div>
        <div className="move" onClick={()=>navigate("/carselection",{state:{type:1}})}>更多{">"}</div>
      </div>
      <div className="hot_brand_list">
        {hot_brand_list.map((i) => {
          return (
            <div className="hot_brand_item" key={i.hot_brand_car_img}>
              <div className="brand_logo">
                <img src={require('../../assets/' + i.brand_logo)} alt=""  />
                {/* <span>奥迪</span> */}
              </div>
              <img src={require('../../assets/' + i.hot_brand_car_img)} alt="" className="hot_brand_car_img" onClick={()=>navigate("/carselection",{state:{type:1}})} />
              <div className="hot_brand_car_class_list">
                {
                  i.hot_brand_car_class_list.map(i=>{
                    return <div className="hot_brand_car_class_item" key={i} onClick={()=>navigate("/carselection",{state:{type:1}})}>{i}</div>
                  })
                }
                <div className="hot_brand_car_class_move" onClick={()=>navigate("/carselection",{state:{type:1}})}>更多{">"}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HotBrand;
