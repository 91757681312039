import React from "react";
import "./Main.scss";
import { Routes, Route } from "react-router-dom";
import Menu from "../Menu/Menu";
import TodayFocus from "../TodayFocus/TodayFocus";
import HotBrand from "../HotBrand/HotBrand";
import CarPictures from "../CarPictures/CarPictures";
import ContactUs from "../ContactUs/ContactUs";
import CarSelection from "../CarSelection/CarSelection";
import CarDetail from "../CarDetail/CarDetail";
import TodayFocusDetail from "../TodayFocusDetail/TodayFocusDetail";
import Login from "../Login/Login";

// import PropTypes from 'prop-types'

function Main(props) {
  return (
    <main>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Menu />
              <TodayFocus />
              <HotBrand />
              <CarPictures />
              <ContactUs />
            </>
          }
        />
        <Route path="/carselection" element={<CarSelection />} />
        <Route path="/cardetail" element={<CarDetail />} />
        <Route path="/todayfocusdetail" element={<TodayFocusDetail />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </main>
  );
}

// Main.propTypes = {}

export default Main;
