import React, { useState } from "react";
import './Login.scss'

function Login() {
  window.scrollTo(0, 0);
  const [type,setType] = useState(0)
  return (
    <div className="login">
      <img src={require('../../assets/car_log_p@2x.png')} alt="" />
      <div className="login_content">
        <div className="title">登录后体验更多精彩</div>
        <div className="login_menu">
            <div className={"item " + (type === 0 ? "login_menu_active" : "")} onClick={()=>setType(0)}>登录</div>
            <div className={"item " + (type === 1 ? "login_menu_active" : "")} onClick={()=>setType(1)}>注册</div>
        </div>  
        
        <div className="content">
            <input type="text" className="username" placeholder="请输入账号" />
            <input type="text" className="password" placeholder="请输入密码" />
            {type === 1 ? <input type="text" className="password" placeholder="请确认密码" /> : null}
            <button className="login_button">{type === 0 ? "登录" : "注册"}</button>
        </div>
      </div>
    </div>
  );
}

export default Login;
