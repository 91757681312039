import React, { useEffect } from "react";
import "./Menu.scss";
import data from "./Menu.json";
// import PropTypes from 'prop-types'
import { useNavigate } from "react-router-dom";

const Menu = React.memo((props) => {
  let navigate = useNavigate();
  useEffect(()=>{
    console.log(123465)
  },[])
  return (
    <div className="menu">
      <div className="menu_nav">
        <div className="title">{data.menu_nav.titlle}</div>
        {data.menu_nav.nav_list.map((i,v) => {
          return (
            <a className="nav_item" key={i.label} href={i.href}>
              {i.label}
            </a>
          );
        })}
      </div>
      <div className="menu_car_class">
        {data.menu_car_class.map((i) => {
          return (
            <div className="menu_car_class_item" key={i.car_class_name}>
              <div className="car_class_name">
                <div className="name">{i.car_class_name}</div>
                <div>{">"}</div>
              </div>
              {i.car_class_name_list.map((i2) => {
                return (
                  <div className="car_class_name_item" key={i2} onClick={()=>navigate("/carselection",{state:{type:1}})}>
                    <div className="title">{i2}</div>
                    <div className="op">
                      <div>报价</div>
                      <div>图片</div>
                      <div>咨询</div>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
})

// Menu.propTypes = {}

export default Menu;
