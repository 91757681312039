import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import app from "../../common/common";
import "./CarDetail.scss";
export default () => {
  const params = useLocation();
  const [carDetail, setCarDetail] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    getCarDetail();
  }, []);

  async function getCarDetail() {
    let a = await app.spiderDetail(params.state.url);
    console.log(a);
    setCarDetail(a);
  }

  return (
    <div className="car_detail">
      <div className="car_detail_nav">
        <Link to={"/"}>车友在线{">"}</Link>
        <span>品牌详情</span>
      </div>

      <div className="car_detail_content">
        <div className="choice_content">
          <div className="car_info_content">
            <img src={carDetail.car_img} alt="" className="car_img" />
            <meta name="referrer" content="no-referrer"></meta>
            <div className="car_info">
              <div className="price">
                官方指导价：<span>{carDetail.price}</span>
              </div>
              <div className="charge_list">
                {carDetail.con
                  ? carDetail.con.map((i, v) => {
                      return v < 2 ? (
                        <div className="item" key={v}>
                          <div>{i.name}</div>
                          <div>{i.price}</div>
                        </div>
                      ) : null;
                    })
                  : null}
              </div>
              <div className="config">
                <div className="item">
                  <span>排 量：</span>1.5L
                </div>
                {carDetail.des
                  ? carDetail.des.map((i) => {
                      return (
                        <div className="item" key={i.span}>
                          <span>{i.span}</span>
                          {i.text}
                        </div>
                      );
                    })
                  : null}
              </div>

              <div className="rush_purchase">半价抢购</div>
            </div>
          </div>

          <div className="price_list">
            <div className="title">车型报价</div>
            {carDetail.con
              ? carDetail.con.map((i,v) => {
                  return (
                    <div className="item" key={v}>
                      <div className="item_name">
                        {i.name}
                      </div>
                      <div className="item_config">
                        {i.config}
                      </div>
                      <div className="item_price">{i.price}</div>
                      <div className="item_button">抢购</div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    </div>
  );
};
